import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index';

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  
  state: {
    apiUrl: 'http://zhoushenghuan.xyz:1667'
    // apiUrl: 'http://localhost:8080'
  },
  getters: {
    // 提供一个getter来获取请求地址
    getApiUrl(state) {
      return state.apiUrl;
    }
  },
  mutations: {
     // 更新请求地址
     updateApiUrl(state, newApiUrl) {
      state.apiUrl = newApiUrl;
      // 将更新后的请求地址存储到localStorage中
      // localStorage.setItem('apiUrl', state.apiUrl);
    }
  },
  actions: {
    // 可以定义一个action来异步更新请求地址
    changeApiUrl({ commit }, newApiUrl) {
      // 执行更新操作
      commit('updateApiUrl', newApiUrl);
    },
    redirectToLogin({ commit }) {
      
      router.push("/login");
    }
  },
  modules: {
  }
})
