<template>
  <div class="container">
    <!-- <div class="head">
      <div class="logo">
        <span>周盛欢</span>
      </div>

      <div class="tabs">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="首页" name="first"></el-tab-pane>
        </el-tabs>
      </div>
      
      <div class="search">
        <input type="text" placeholder="请输入关键词"></input>
        <div class="search-icon">
          <img src="../assets/sousuo.png" />
        </div>
      </div>

      <div class="avatar-view">
        <el-avatar :size="40" :src="url"></el-avatar>
      </div>
    </div> -->
    <Header></Header>
    <div class="functionCard">
      <div class="ArticleCard">
        <div class="Sort">
          <div v-for="item in sort" :key="item">
            {{ item }}
          </div>
        </div>

        <hr class="custom-hr" />
        <div
          class="crad"
          v-for="(article, index) in articles"
          :key="index"
          @click="goToArticle(article.id)"
        >
          <div class="crad-1">
            <div class="left">
              <div class="title">{{ article.title }}</div>
              <div class="intro">{{ article.intro }}</div>
            </div>
            <div class="cover">
              <img :src="article.cover" />
            </div>
          </div>
          <hr class="custom-hr" />
        </div>
      </div>
      <div class="toolbox">
        <div class="Toolbox_text">工具箱</div>
        <div class="orientation">
          <el-button type="primary" @click="pdfToWord">PDF</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    // 映射 Vuex state 中的属性到计算属性
    ...mapState(["apiUrl"]),
  },
  data() {
    return {
      activeName: "first",
      // input3: "",
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      sort: ["推荐"],
      articles: [
        {
          id: 1,
          title: "其他文章标题",
          cover:
            "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
          intro: "这是其他文章的简介。",
          content: "",
        },
        {
          id: 2,
          title: "其他文章标题",
          cover:
            "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
          intro: "这是其他文章的简介。",
          content: "",
        },
      ],
    };
  },
  mounted() {
    console.log("刷新页面");
    this.GetArticleList();
  },
  methods: {
    ...mapMutations([
      "updateApiUrl", // 映射 'updateApiUrl' mutation
    ]),
    ...mapGetters([
      "getApiUrl", // 映射 'getApiUrl' getter
    ]),
    handleClick(tab, event) {
      console.log(tab, event);
    },
    GetArticleList() {
      let ApiBaseUrl = this.getApiUrl();
      console.log("ApiBaseUrl", ApiBaseUrl);

      this.$http
        .post(
          ApiBaseUrl + "/api/user/article/GetArticleList",
          {
            // 请求体数据
          },
          {
            headers: {
              "Content-Type": "application/json", // 显式设置请求头为 JSON
            },
          }
        )
        .then((response) => {
          // 处理响应
          console.log(response.data);
          // 假设响应数据中包含文章列表，可以将其赋值给 articles
          this.articles = response.data.articleList;
        })
        .catch((error) => {
          // 处理错误
          console.error("请求失败:", error);
        });
    },
    goToArticle(id) {
      this.$router.push({ path: "/ArticleDetail", query: { id: id } });
    },
    pdfToWord() {
      let ApiBaseUrl = this.getApiUrl();
      console.log("ApiBaseUrl", ApiBaseUrl);
      const url = ApiBaseUrl + "/api/user/conver/test";
      this.$http
        .get(url, {})
        .then((response) => {})
        .catch((error) => {});
    },
  },
};
</script>

<style  scoped>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%; /* 确保body元素高度为100% */
}

.container {
  background-color: #f4f6f9;
  height: 100vh; /* 使.container高度为100%的视口高度 */
  margin: 0; /* 移除外边距 */
  padding: 0; /* 移除内边距 */
  overflow: auto;
}

.head {
  background-color: #ffffff;
  width: 100%;
  height: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.tabs {
  margin-left: 30px;
}

.search {
  margin-left: 100px;
  margin-bottom: 5px;
}

.msg {
  margin-left: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.msg img {
  width: 20px;
  height: 20px;
}

.msg {
  color: #c4c4c4;
  font-size: 10px;
}

.avatar-view {
  margin-left: 120px;
}

.ArticleCard {
  margin-top: 10px;
  margin-left: 150px;
  width: 690px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.Sort {
  display: flex;
  margin-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.Sort div {
  margin-right: 45px; /* 右边距，根据需要调整 */
}

.crad-1 {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}

.left {
  margin-left: 30px;
  margin-right: 10px;
}

.cover {
  margin-left: auto; /* 将.cover推向右侧 */
  margin-right: 20px;
}

.cover img {
  width: 190px;
  height: 110px;
  border-radius: 10px;
}

.intro {
  margin-top: 10px;
}

.title {
  font-size: 18px; /* 根据需要调整字体大小 */
  font-weight: bold; /* 字体加粗 */
  margin-bottom: 10px; /* 根据需要添加底部外边距 */
}

.logo {
  margin-left: 60px;
}

.logo span {
  color: #1772f6; /* 设置文字颜色为蓝色 */
  font-family: "宋体", SimSun, serif; /* 设置字体为宋体 */
  font-size: 25px;
}

.search {
  display: flex; /* 启用Flexbox */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  width: 412px; /* 宽度 */
  height: 40px; /* 高度 */
  background-color: #f3f5f7; /* 背景颜色 */
  border-radius: 20px; /* 边框圆角 */
  padding-left: 19px; /* 左内边距 */
  padding-right: 12px; /* 右内边距 */
}

.search input {
  flex: 1;
  border: 0;
  background-color: transparent;
  outline: none;
}

.search-icon img {
  width: 20px;
  height: 20px;
}

.title {
  font-size: 18px; /* 根据需要调整字体大小 */
  font-weight: bold; /* 字体加粗 */
  margin-bottom: 10px; /* 根据需要添加底部外边距 */
  display: -webkit-box; /* 使用弹性盒子布局 */
  -webkit-line-clamp: 2; /* 限制文本行数为2行 */
  -webkit-box-orient: vertical; /* 设置盒子布局的方向为纵向 */
  overflow: hidden; /* 隐藏溢出的文本 */
  text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
}

.crad:hover {
  background-color: #f0f0f0; /* 悬停时的背景颜色 */
}

.crad {
  /* 其他样式... */
  transition: background-color 0.3s; /* 添加一个平滑的过渡效果 */
}

.custom-hr {
  border: 0;
  height: 1px; /* 根据需要调整高度 */
  background-color: #cccccc; /* 设置分割线的颜色 */
  margin-top: 0px; /* 调整上间距 */
  margin-bottom: 0px; /* 调整下间距 */
}

.functionCard {
  display: flex;
}

.toolbox {
  margin-top: 10px;
  margin-left: 10px;
  width: 300px;
  height: 310px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.Toolbox_text {
  margin-top: 10px;
  margin-left: 10px;
}

.orientation {
  margin-top: 10px;
  margin-left: 10px;
}
</style>